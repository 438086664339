<template>
  <div class="input">
    <div class="input__content">
      <div class="input__select" @click="toggleOptions" v-if="options.length > 1">
        <div class="input__select--option">{{ selectedOption }}<iconic name="chevronDown" id="chevronDown" :style="{ transform: chevronRotation }"></iconic></div>
      </div>
      <div class="input__select" v-else>
        <div class="input__select--option">{{ selectedOption }}</div>
      </div>
      <div class="input__list" v-if="showOptions">
        <div v-for="(option, index) in options" :key="index" class="option" @click="selectOption(option)">
          <span>{{ option }}</span>
        </div>
      </div>
      <div class="input__check" v-else>
        <input type="checkbox" :id="`requireImages-${idx}`" @change="toggleRequireImages" :checked="requireImages" />
        <input type="hidden" :name="`${name}-${idx}/requireImages`" :value="requireImages" />
        <label :for="`requireImages-${idx}`">Permitir imagenes</label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["options", "idx", "name", "requireImages", "typeSelect"],
    data() {
      return {
        showOptions: false,
        selectedOption: "Casillas de verificación",
        dicQuestions: {
          multipleOption: "Casillas de verificación",
          onlyOption: "Opcion multiple",
          freeAnswer: "Respuesta abierta",
        },
      };
    },
    computed: {
      chevronRotation() {
        return this.showOptions ? "rotate(180deg)" : "";
      },
    },
    methods: {
      toggleOptions() {
        this.showOptions = !this.showOptions;
      },
      selectOption(option) {
        this.selectedOption = option;
        this.showOptions = false;
        this.$emit("onOption", option);
      },
      toggleRequireImages(evt) {
        const isChecked = evt.target.checked;
        this.$emit("toggleRequireImages", isChecked);
      },
      handleTypeExists() {
        if (this.typeSelect) {
          this.selectedOption = this.dicQuestions[this.typeSelect];
        }
      },
    },
    mounted() {
      this.handleTypeExists();
    },
  };
</script>

<style lang="scss">
  .input {
    &__select {
      width: 100%;
      height: 39px;
      border-radius: 6px;
      background-color: #fff;
      padding: 5px 10px 10px 10px;
      border: 1px solid #00000040;
      &--option {
        display: flex;
        justify-content: space-between;
      }
    }
    &__check {
      margin-top: 5px;
      cursor: pointer;
      input {
        margin-right: 5px;
      }
    }
    &__list {
      width: 295px;
      margin: 4px 0;
      cursor: pointer;
      border-radius: 6px;
      background-color: #fff;
      padding: 5px 10px 10px 10px;
      border: 1px solid #00000040;
      .option:hover {
        background-color: #d7d8d8;
      }
    }
  }
</style>
